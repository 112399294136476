.appHeader {
    background-color: #fafafb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
    min-width: 850px;
}

.appHeader a {
    font-family: Montserrat, Lato, sans-serif;
    color: #444;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    line-height: 25px;
}

.appHeader a:hover {
    color: #0d4391;
}

a.interreg {
    margin: 20px;
}

a.synergyLogo {
    /* height: 140px; */
    margin-top: 20px;
    margin-right: 15px;
    background-color: #fff !important;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, .2);
}

.synergyLogo img {
    width: 190px;
    margin: 17px 10px 10px 10px;
}

.priority {
    font-family: Montserrat, Lato, sans-serif;
    font-weight: 700;
    padding: 15px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #fec617;
}

.priority .microscope {
    width: 22px;
    vertical-align: middle;
    margin-right: 8px;
}

.userMenuCard .menu, .dropdown .menu {
    z-index: 1000 !important;
}

@media only screen and (max-width: 900px) {
    .linkInterreg {
        display: none;
    }
    .appHeader {
        justify-content:flex-end;
        margin-top: 0px;
        min-width: 350px;
    }
    .headerRight {
        display: flex;
    }
    .headerRightElement {
        display: block;
    }
    .priority {
        height: 58px;
        font-weight: 600;
        padding: 5px;
        font-size: 10px;
    }
    .priority .microscope {
        display: none;
    }
    a.synergyLogo {
        margin-top: 0px;
        margin-right: 0px;
        width: 110px;
    background-color: #fff !important;
    }
    .synergyLogo img {
        width: 90px;
        margin: 0px 0px 10px 10px;
        align-self: flex-end;
    }
}