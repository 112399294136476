.infrastructure {
    margin-top: -1em;
    display: flex;
    position: relative;
}

.infrastructureViewModeToggle .toggle:after {
    content: none;
}

.infrastructure-search .search-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.infrastructure-search {
    /* height: calc(100vh - 184px); */
    flex: 1 1;
    margin-top: 1em;
    padding: 10px;
}

.infrastructure-list, .infrastructure-map {
    flex: 3 1;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.ant-upload-list-picture img.ant-upload-list-item-image {
    display: block;
    height: 48px;
    overflow: hidden;
    max-width: 48px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

.fileWall {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.singleUpload .ant-upload-list-item {
    width: 100%;
    height: 100%;
}

.generalInformationSingleUpload {
    float: right;
    margin: 0 0 1em 1em;
}

/* Wymagane do poprawnego działania float w formularzu zakładki General information */

.stepContentInfrastructure .field {
    clear: none !important;
    display: flex;
    flex-direction: column;
}

.stepContentInfrastructure .geolocation {
    align-items: flex-end;
}

/* Miniatura drop zone (gdy brak obrazka) w zakładce General information **/
.generalInformationSingleUpload .ant-upload.ant-upload-select-picture-card {
    min-width: 250px;
    height: 250px;
}

.generalInformationSingleUpload .ant-upload-list-picture-card-container {
    width: auto;
    height: auto;
    margin: 0;
}

/* Miniatura obrazka w zakładce General information */
.generalInformationSingleUpload img.ant-upload-list-item-image {
    max-width: 300px;
    max-height: 300px;
}

.ant-modal.fileWallImgPreviewModal {
    width: auto !important;
}

img.fileWallImgPreview {
    max-width: 100%;
}

.ant-upload-list-item-name {
    cursor: pointer;
}

fieldset.segment legend {
    font-size: 100%;
}

.leaflet-container {
    height: calc(100vh - 170px);
    width: 100%;
    margin: 0 auto;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.leaflet-container .header {
    font-size: 110%;
    margin-bottom: 0.5em;
}

.leaflet-container .buttons {
    text-align: right;
}

.leaflet-control-container .leaflet-top {
    z-index: 999;
}

.geolocation input[type=number]::-webkit-inner-spin-button, 
.geolocation input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.modal.geolocation .content {
    padding: 0;
}

.leaflet-control.infrastructure {
    margin: 0;
}

.leaflet-control .infrastructureViewModeToggle {
    margin: 2.5em 0 2em 1.7em;
}
.infrastructureViewModeToggle {
    margin: 2em 1.5em;
    float: left;
}

label .charLimit {
    float: right;
    color: #999;
    font-weight: normal;
}

.leaflet-popup-content .content.withImage {
    min-height: 100px;
    /* padding-bottom: 2em; */
    display: flex;
}

.leaflet-popup-content .content.withImage img {
    max-height: 140px;
    max-width: 140px;
    width: auto;
    height: auto;
}

.leaflet-popup.withImage .leaflet-popup-content {
    min-width: 100px;
}

img.infrastructureListOverview {
    margin-top: 0.5em;
}

button.contactWithOwner {
    float: left;
}

.infrastructureContact.modal .nameEditLabel {
   font-size: 100%;
}

.infrastructureContact.modal .header {
    display: flex;
    align-items: center;
}

.infrastructureContact.modal .header .icon {
    margin-right: 0.5em;
}

.infrastructureContact.modal .slotUse.inline .react-datepicker-wrapper {
    margin-right: 0.2em !important;
}

.infrastructureResearchEquipment {
    margin: 15px 0;
}