.camt {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../assets/camt.jpg);
    /* padding: 220px 0; */
    height: 80vh;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.camt .sheader {
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 40px 20px;
    background: rgba(0, 22, 61, .8);
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.camt .sbuttons {
    margin: 1.5em;
}

.camt .sbuttons .button {
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0 .5em;
    background: #fff;
    color: #000;
    border-radius: 0;
    padding: 10px 50px;
}

.camt .sbuttons .button:hover {
    background: #d6730e;
    color: #fff;
}

.modules {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5em;
    margin-top: 1.5em;
}
.modules .button {
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    background: #fff;
    color: #000;
    border-radius: 0;
    width: 100%;
}
.modules .button:hover {
    background: #d6730e;
    color: #fff;
}

.module_box {
    height: 14em;
    min-width: 200px;
    overflow: hidden;
}
#project_box{
    width: 200px;
}
#organization_box {
    width: 220px;
}
#map_box {
    width: 200px;
}
#infrastructure_box {
    width: 300px;
}
#crowdfunding_box {
    width: 200px;
}
#trainings_box {
    width: 180px;
}
#inspiration_box {
    width: 330px;
}
.module_info {
    height: 100%;
    padding: .5em;
    background: rgba(0, 22, 61, .8);
    color: #fff;
    text-align: center;
}
        .link_box {
            width: 220px;
        }

@media only screen and (max-width: 850px) {
    .camt {
        height: 1800px;
    }
    .module_box {
        height: 14em;
    }
    #project_box{
        width: 300px;
        height: 120px;
    }
    #organization_box {
        width: 300px;
        height: 140px;
    }
    #map_box {
        width: 300px;
        height: 140px;
    }
    #infrastructure_box {
        width: 300px;
        height: 160px;
    }
    #crowdsourcing_box {
        width: 300px;
    }
        #crowdfunding_box {
            width: 300px;
        }    #trainings_box {
        width: 300px;
    }
    #inspiration_box {
        width: 300px;
    }
    .module_info {
        width: 300px;
    }
    #link_box {
        width: 300px;
        height: 140px;
    }
}
